<template>
  <section class="services bg-black text-light py-5" id="services">
    <b-container>
      <h2 class="mb-4 font-weight-bold">OUR SERVICES</h2>
      <b-row align-h="start">
        <b-col class="py-2" cols="auto">
          <div class="service rounded-0 overflow-hidden">
            <div class="img">
              <img :src="require('../../../assets/img/web.gif')" alt="" />
            </div>
            <h5 class="mt-3 px-2">Web Development</h5>
          </div>
        </b-col>
        <b-col class="py-2" cols="auto">
          <div class="service rounded-0 overflow-hidden">
            <div class="img">
              <img :src="require('../../../assets/img/mobile.gif')" alt="" />
            </div>
            <h5 class="mt-3 px-2">Mobile App Development</h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.services {
  .img {
    max-height: 300px;
    overflow: hidden;
    margin: 5px;

    img {
      max-height: inherit;
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
</style>