<template>
  <nav class="" :class="{ mobile: isMobile, opened }" ref="navbar">
    <li class="logo py-0"><a href="#">QUARKSGROUP</a></li>

    <li class="toggle py-0">
      <b-icon-list
        variant="light"
        font-scale="1.75"
        @click="opened = !opened"
      />
    </li>

    <ul class="menu mb-0 py-0">
      <li class="item py-0">
        <b-link to="homepage" class="py-0" href="#">Home</b-link>
      </li>
      <li class="item py-0">
        <b-link class="py-0" @click="goto('#about')">About</b-link>
      </li>
      <li class="item py-0">
        <b-link class="py-0" @click="goto('#services')">Services</b-link>
      </li>
      <li class="item py-0">
        <b-link class="py-0" to="products">Products</b-link>
      </li>
      <li class="item py-0">
        <b-link to="/story" class="py-0">Our Story</b-link>
      </li>
      <li class="item py-0">
        <b-link class="py-0" @click="goto('#contact')">Contact</b-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      opened: true,
    };
  },
  mounted() {
    this.isMobile = innerWidth <= 800;

    window.addEventListener("resize", () => {
      this.isMobile = innerWidth <= 800;
      console.log(this.isMobile);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {});
  },
  methods: {
    goto(hash) {
      this.$router
        .push({ path: "/", hash: hash })
        .then()
        .catch((err) => {
          document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
        });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: #111;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;

  a {
    color: white;
    text-decoration: none;
  }

  li {
    list-style: none;
  }

  .logo {
    font-size: 20px;
    padding: 22px;
  }
  .item {
    padding: 10px;
    order: 3;
    text-align: center;

    &.button {
      padding: 9px 5px;
      order: 2;
    }

    &:not(.button) a:hover,
    & a:hover::after {
      color: #ccc;
    }
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .submenu {
      list-style-type: none;
    }
    li {
      a {
        display: block;
        padding: 15px 5px;
      }

      &.subitem a {
        padding: 15px;
      }
    }
  }

  .active {
    .item {
      display: block;
    }
  }

  .toggle {
    display: none;
    padding-right: 22px;
    cursor: pointer;
  }

  &.mobile {
    .menu {
      height: 0;
      width: 100%;
      overflow: hidden;
      flex-direction: column;
      transition: all 0.5s;
      flex-wrap: nowrap;
    }
    .toggle {
      display: inline-block;
    }

    &.opened {
      .menu {
        height: 200px;
        width: 100%;
        order: 3;
        margin-top: 30px;
      }
    }
  }
}
</style>