import Vue from "vue";
import VueRouter from "vue-router";
import metaHandler from "../helpers/metaHandler";

import { Home, Products, Story } from "../pages/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "homepage",
    component: Home,
    meta: {
      guest: true,
      title: "Home | Quarks Group Ltd.",
      metaTags: [
        {
          name: "description",
          content: "Home | Quarks Group Ltd.",
        },
        {
          property: "og:description",
          content: "A Passionate, dedicated, focus driven company.",
        },
      ],
    },
  },
  {
    path: "/products",
    name: "products",
    component: Products,
    meta: {
      guest: true,
      title: "Products | Quarks Group Ltd.",
      metaTags: [
        {
          name: "description",
          content: "Quarks Group products",
        },
        {
          property: "og:description",
          content: "Products designed and implemented by quarks group.",
        },
      ],
    },
  },
  {
    path: "/story",
    name: "story",
    component: Story,
    meta: {
      guest: true,
      title: "Story | Quarks Group Ltd.",
      metaTags: [
        {
          name: "description",
          content: "Quarks Group Story",
        },
        {
          property: "og:description",
          content: "Story of quarks group.",
        },
      ],
    },
  },
  {
    path: "*",
    redirect: { name: "homepage" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: "smooth" });
        }, 300);
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  metaHandler(to, from, next);
});

export default router;
