<template>
  <footer class="footer py-5 bg-black">
    <b-container>
      <b-row>
        <b-col>
          <h5 class="text-light mb-3">QUARKS GROUP</h5>
          <div class="mb-1">
            <a href="tel:+250785868145">+250 785 868 145</a>
          </div>
          <div class="mb-2">
            <a href="mailto:info@quarksgroup.rw">info@quarksgroup.rw</a>
          </div>
        </b-col>
        <b-col cols="auto" class="align-self-end">
          <b-row>
            <b-col>
              <b-link
                href="https://www.instagram.com/quarksgroup/"
                target="_blank"
                ><b-icon-instagram variant="light"
              /></b-link>
            </b-col>
            <b-col>
              <b-link href="https://twitter.com/QuarksG" target="_blank"
                ><b-icon-twitter variant="light"
              /></b-link>
            </b-col>
            <b-col>
              <b-link href="https://github.com/quarksgroup" target="_blank">
                <b-icon-github variant="light"
              /></b-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  li {
    list-style: none;
  }
  a {
    color: white;
    text-decoration: none;
  }
  .item {
    padding: 10px;
    order: 3;
    text-align: center;

    &.button {
      padding: 9px 5px;
      order: 2;
    }

    &:not(.button) a:hover,
    & a:hover::after {
      color: #ccc;
    }
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    .submenu {
      list-style-type: none;
    }
    li {
      a {
        display: block;
        padding: 15px 5px;
      }

      &.subitem a {
        padding: 15px;
      }
    }
  }
}
</style>