<template>
  <main>
    <navigation />
    <section class="products bg-dark py-5">
      <div class="mb-4 sticky-top bg-dark nav-container">
        <b-row class="tab-navs mx-0" align-h="center" align-v="center">
          <b-col class="py-2" cols="auto">
            <b-button
              :variant="selectedTab == 'owned' ? 'light' : 'outline-light'"
              @click="selectedTab = 'owned'"
            >
              Owned Products
            </b-button>
          </b-col>
          <b-col class="py-2" cols="auto">
            <b-button
              :variant="selectedTab == 'not-owned' ? 'light' : 'outline-light'"
              @click="selectedTab = 'not-owned'"
            >
              Commissioned Products
            </b-button>
          </b-col>
        </b-row>
      </div>
      <transition name="component-fade" mode="in-out">
        <b-container v-if="selectedTab == 'owned'">
          <b-row cols="12">
            <b-col
              v-for="(product, i) in owned_products"
              :key="i"
              class="text-light my-3"
            >
              <b-row class="product">
                <b-col class="my-3" cols="12" md="6" xl="5">
                  <div class="preview overflow-hidden">
                    <a :href="product.link" target="_blank">
                      <b-img :src="product.url" />
                    </a>
                  </div>
                </b-col>
                <b-col class="my-3">
                  <h4 class="mb-3 font-weight-bold">
                    <a class="text-light" :href="product.link" target="_blank">
                      {{ product.name }}
                    </a>
                  </h4>
                  <article>
                    <p>
                      Paypack is a self-service payment platform. Our end goal
                      is to build and automate a payment gateway that is easy to
                      use, secure, and affordable.
                    </p>
                    <p>
                      Its design philosophy follows a developer-first approach
                      since they are the ones who are going to be intergrating
                      our payment gateway into their systems.
                    </p>
                    <p>
                      Clients with businesses that accept or issue payments can
                      use our payment gateway to receive and send funds.
                    </p>
                  </article>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-if="selectedTab == 'not-owned'">
          <b-row cols="12" cols-md="2">
            <b-col
              v-for="(product, i) in commisioned_products"
              :key="'not-owned' + i"
              class="text-light my-4"
            >
              <div class="product">
                <div class="preview overflow-hidden">
                  <a :href="product.link" target="_blank">
                    <b-img :src="product.url" />
                  </a>
                </div>
                <h5 class="mt-3">
                  <a class="text-light" target="_blank" :href="product.link">
                    {{ product.name }}
                  </a>
                </h5>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </transition>
    </section>
    <footer-section />
  </main>
</template>

<script>
import navigation from "../sections/nav.vue";
import footerSection from "../sections/footer.vue";
export default {
  components: { navigation, footerSection },
  data() {
    return {
      selectedTab: "owned",
      owned_products: [
        {
          name: "Paypack Payments",
          url: require("../../assets/img/products/paypack payments.png"),
          link: "https://payments.paypack.rw",
        },
      ],
      commisioned_products: [
        {
          name: "Smartclass",
          url: require("../../assets/img/products/smartclass.png"),
          link: "https://smartclass.rw",
        },
        {
          name: "Nile Water Africa",
          url: require("../../assets/img/products/nile water africa.png"),
          link: "https://nilewaterafrica.com/",
        },
        {
          name: "Nguyen CPA",
          url: require("../../assets/img/products/nguyen cpa.png"),
          link: "https://nguyencpa.net/",
        },
        {
          name: "Nano Investment",
          url: require("../../assets/img/products/nano investment.png"),
          link: "https://nanoinvestment.rw/",
        },
        {
          name: "NK3D Studio",
          url: require("../../assets/img/products/nk3d studio.png"),
          link: "https://www.nk3dstudio.rw/",
        },
        {
          name: "Royal Pets Center",
          url: require("../../assets/img/products/royal pets.png"),
          link: "https://www.royalpetscenter.rw/",
        },
        {
          name: "Rweru Environmental concern",
          url: require("../../assets/img/products/rweru environmental concern.png"),
          link: "https://www.environmentalconcern.co.rw/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.products {
  .tab-navs {
    position: sticky;
    top: 0;
  }
  .preview {
    width: 100%;
    height: auto;
    min-height: 140px;
    user-select: none;
    position: relative;
    border: 0.1px solid #4a4a4a;
    background-color: #4a4a4a;

    img {
      width: 100%;
      aspect-ratio: 16/10;
      object-fit: cover;
      object-position: top;
    }

    &:hover {
      .link {
        transform: scale(1);
      }
    }

    .link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #00000099;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: scale(0);
      transition: all 0.2s linear;

      a {
        padding: 4px 10px;
        color: var(--light);
        transition: all 0.15s linear;
        text-decoration: none;
        &:hover {
          background-color: var(--light);
          color: var(--dark);
        }
      }
    }
  }
}
</style>
