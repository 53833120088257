<template>
  <section class="py-5 team">
    <b-container class="text-light">
      <h2 class="text-center">Meet The Team</h2>
      <b-row cols="4" align-h="center" align-v="center">
        <b-col>
          <b-icon-person-badge variant="light" />
        </b-col>
        <b-col>
          <b-icon-person-badge variant="light" />
        </b-col>
        <b-col>
          <b-icon-person-badge variant="light" />
        </b-col>
        <b-col>
          <b-icon-person-badge variant="light" />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.team {
  background-color: #111;
}
</style>