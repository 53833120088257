<template>
  <main>
    <navigation />
    <hero />
    <about />
    <services />
    <!-- <team /> -->
    <contact />
    <footer-section />
  </main>
</template>

<script>
import navigation from "../sections/nav.vue";
import hero from "./sections/hero.vue";
import about from "./sections/about.vue";
import team from "./sections/team.vue";
import services from "./sections/services.vue";
import contact from "./sections/contact.vue";
import footerSection from "../sections/footer.vue";
export default {
  components: {
    navigation,
    hero,
    about,
    team,
    services,
    contact,
    footerSection,
  },
};
</script>

<style lang="scss" scoped>
</style>